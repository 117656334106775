body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-family: 'Raleway', sans-serif;
}

h3 {
  font-family: 'Raleway', sans-serif;
}

h4 {
  font-family: 'Raleway', sans-serif;
}

h5 {
  font-family: 'Raleway', sans-serif;
}

p {
  font-family: 'Montserrat', sans-serif;
}

.slick-slide.slick-center img { margin-left: auto; margin-right: auto; }

